import dayjs from 'dayjs';
import {MONTH_DAY_YEAR_FORMAT} from '@/config/timeFormats';
import subscriptionFetchers from '@/api/fetchers/subscription';
import useDataQuery from '@/hooks/use-data-query';
import useLtxAuthToken from '@/hooks/use-ltx-auth-token';

const SUBSCRIPTION_NAME_MAP = {
  platform_free: 'starter',
} as any;

function useSubscriptionQuery({
  brandId,
  enabled = true,
}: {
  brandId?: string;
  enabled?: boolean;
}) {
  const {ltxAuthToken} = useLtxAuthToken();

  const response: any = useDataQuery({
    queryKey: ['subscription', brandId],
    queryFn: subscriptionFetchers.subscription,
    refetchOnWindowFocus: false,
    cacheTime: 1800, // 30 minutes
    staleTime: 600, // 10 minutes
    enabled: !!brandId && !!ltxAuthToken && enabled,
    meta: {
      brandId,
    },
  });

  const subscription = () => {
    return response.data || {};
  };

  const subscriptionType = () => {
    return subscription().subscriptionType;
  };

  const expirationDate = () => {
    return subscription().expirationDate;
  };
  const isExpired = () => {
    return dayjs().isAfter(expirationDate());
  };

  const expiresInDays = () => {
    return dayjs(expirationDate()).diff(dayjs(), 'days');
  };

  const displayDate = () => {
    return dayjs(expirationDate()).format(MONTH_DAY_YEAR_FORMAT);
  };

  const isPlatform = () => {
    return ['platform', 'platform_free'].includes(subscriptionType());
  };

  const isFree = () => {
    return subscriptionType() === 'platform_free';
  };

  const isEssentials = () => {
    return subscriptionType() === 'essentials';
  };

  const isCampaign = () => {
    return subscriptionType() === 'campaign';
  };

  const displayName = () => {
    return (
      SUBSCRIPTION_NAME_MAP[subscriptionType()] ||
      subscriptionType()?.replace(/_/g, ' ')
    );
  };

  const isSelfServe = () => {
    return isFree() || isEssentials();
  };

  const plan = () => {
    return subscription().plan;
  };

  const requestedPlan = () => {
    return subscription().requestedPlan;
  };

  return {
    ...response,
    subscription: {
      ...subscription(),
      loaded: enabled && !!brandId && response.isFetched,
      subscriptionType: subscriptionType(),
      expirationDate: expirationDate(),
      isExpired: isExpired(),
      expiresInDays: expiresInDays(),
      displayDate: displayDate(),
      isPlatform: isPlatform(),
      isFree: isFree(),
      isEssentials: isEssentials(),
      displayName: displayName(),
      isSelfServe: isSelfServe(),
      isCampaign: isCampaign(),
      plan: plan(),
      requestedPlan: requestedPlan(),
    },
  };
}

export default useSubscriptionQuery;
