import dayjs from 'dayjs';
import {
  capitalize,
  difference,
  get,
  omit,
  orderBy,
  pickBy,
  snakeCase,
} from 'lodash';
import {useMemo} from 'react';
import {PostAnalysis} from '@/types/creatorSafetyReport';
import {TimeRange} from '@/types/timeRange';
import {
  Severity,
  VETTING_CATEGORY,
  VETTING_FLAGS_TO_CATEGORY_MAPPING,
} from '@/components/creator-vetting-report/constants';
import getSeverityFromFlagScore from '@/components/creator-vetting-report/utils/getSeverityFromFlagScore';

interface Flag {
  id: string;
  name: string;
  postsAmount: number;
  percentage: number;
  posts: PostAnalysis[];
}

export interface VettingCategory {
  id: string;
  name: string;
  postsAmount: number;
  percentage: number;
  flags: Flag[];
}

export interface FlagWithCategory extends Flag {
  category: Omit<VettingCategory, 'flags'>;
}

export interface VettingCategories {
  flagged: VettingCategory[];
  cleared: VettingCategory[];
  flatFlaggedWithCategory: FlagWithCategory[];
}

interface FlagAcc extends Flag {
  topScore: number;
}

interface FlagObjectAcc {
  [key: string]: FlagAcc;
}

interface VettingCategoryAcc extends Omit<VettingCategory, 'flags'> {
  topScore: number;
  flags: FlagObjectAcc;
}

interface CategoryAcc {
  [key: string]: VettingCategoryAcc;
}

interface VettingFilters {
  timeRange: TimeRange;
  severity: Severity;
}

function getCategoryFlags(category: string): FlagObjectAcc {
  return Object.entries(VETTING_FLAGS_TO_CATEGORY_MAPPING)
    .filter(([_, flagCategory]) => flagCategory === category)
    .reduce((acc: FlagObjectAcc, [flag]) => {
      acc[flag] = {
        id: flag,
        name: flag,
        postsAmount: 0,
        percentage: 0,
        topScore: 0,
        posts: [],
      };
      return acc;
    }, {});
}

function getPrettyName(name: string): string {
  const splitName = name.split('_');
  const [firstWord, ...rest] = splitName;

  return [
    capitalize(firstWord.toLowerCase()),
    ...rest.map((word) => word.toLowerCase()),
  ].join(' ');
}

function getPostIsAtGivenSeverity(
  severity: Severity,
  post: PostAnalysis,
  flag: string
): boolean {
  if (severity === Severity.ALL) {
    return true;
  }
  const analysisWithoutSummary = omit(
    post.mediaAnalysisResult.brandSafetyAnalysis,
    ['summary']
  );

  const flagScore = get(analysisWithoutSummary, `${flag}.score`, 0);
  const flagSeverity = getSeverityFromFlagScore(flagScore);
  return flagSeverity === severity;
}

function filteredPosts(
  posts: PostAnalysis[],
  vettingFilters: VettingFilters,
  flag: string
): PostAnalysis[] {
  return posts.filter((post) => {
    const {timeRange} = vettingFilters;
    if (!timeRange.start || !timeRange.end) {
      return true;
    }
    const {createdAt} = post.postMetadata;
    const createdAtDate = dayjs(createdAt).toDate();
    const isAtGivenCreatedAtDateRange =
      createdAtDate >= timeRange.start && createdAtDate <= timeRange.end;
    const isAtGivenSeverity = getPostIsAtGivenSeverity(
      vettingFilters.severity,
      post,
      flag
    );
    return isAtGivenCreatedAtDateRange && isAtGivenSeverity;
  });
}

function useGetVettingCategories(
  flaggedPosts: PostAnalysis[],
  vettingFilters: VettingFilters
): VettingCategories {
  const flaggedCategories = useMemo(
    () =>
      flaggedPosts.reduce((acc: CategoryAcc, post) => {
        const analysisWithoutSummary = omit(
          post.mediaAnalysisResult.brandSafetyAnalysis,
          ['summary']
        );
        const relevantFlags = pickBy(
          analysisWithoutSummary,
          (value) => !!value.score
        );
        Object.keys(relevantFlags).forEach((flag) => {
          if (!(flag in VETTING_FLAGS_TO_CATEGORY_MAPPING)) {
            return;
          }
          const category = VETTING_FLAGS_TO_CATEGORY_MAPPING[flag];
          if (!acc[category]) {
            acc[category] = {
              id: category,
              name: category,
              postsAmount: 0,
              percentage: 0,
              topScore: 0,
              flags: getCategoryFlags(category),
            };
          }
          acc[category].postsAmount += 1;
          const flagScore = relevantFlags[flag]?.score || 0;
          if (flagScore > acc[category].topScore) {
            acc[category].topScore = flagScore;
          }
          if (!acc[category].flags[flag]) {
            acc[category].flags[flag] = {
              id: flag,
              name: flag,
              postsAmount: 0,
              percentage: 0,
              topScore: 0,
              posts: [],
            };
          }
          acc[category].flags[flag].postsAmount += 1;
          acc[category].flags[flag].topScore = acc[category].topScore;
          acc[category].flags[flag].posts.push(post);
        });

        return acc;
      }, {}),
    [flaggedPosts]
  );

  const flagged = useMemo(
    () =>
      orderBy(
        Object.values(flaggedCategories).map((category) => ({
          id: category.name,
          name: getPrettyName(category.name),
          postsAmount: category.postsAmount,
          percentage: category.topScore * 100, // TODO: calculate percentage according to PRD
          flags: orderBy(
            Object.values(category.flags).map((flag) => ({
              ...flag,
              percentage: flag.topScore * 100, // // TODO: calculate percentage according to PRD
              name: getPrettyName(snakeCase(flag.name)),
            })),
            ['name'],
            ['asc']
          ),
        })),
        ['percentage'],
        ['desc']
      ),
    [flaggedCategories]
  );

  const clearedCategories = useMemo(
    () =>
      difference(
        Object.keys(VETTING_CATEGORY),
        flagged.map((category) => category.id)
      ),
    [flagged]
  );

  const cleared = useMemo(
    () =>
      orderBy(
        clearedCategories.map((category) => ({
          id: category,
          name: getPrettyName(category),
          postsAmount: 0,
          percentage: 0,
          flags: orderBy(
            Object.values(getCategoryFlags(category)).map((flag) => ({
              ...flag,
              name: getPrettyName(snakeCase(flag.name)),
            })),
            ['name'],
            ['asc']
          ),
        })),
        ['name'],
        ['asc']
      ),
    [clearedCategories]
  );

  const flatFlaggedWithCategory = useMemo(
    () =>
      orderBy(
        flagged.reduce((acc: FlagWithCategory[], category) => {
          const filteredFlaggedCategories = category.flags
            .filter((flag) => flag.postsAmount > 0)
            .map((flag) => {
              const posts = orderBy(
                filteredPosts(flag.posts, vettingFilters, flag.id),
                [`mediaAnalysisResult.brandSafetyAnalysis.${flag.id}.score`],
                ['desc']
              );
              return {
                ...flag,
                posts,
                postsAmount: posts.length,
                category: {
                  id: category.id,
                  name: category.name,
                  postsAmount: posts.length,
                  percentage: category.percentage,
                },
              };
            });

          return acc.concat(
            filteredFlaggedCategories.filter((flag) => flag.postsAmount > 0)
          );
        }, []),
        ['percentage'],
        ['desc']
      ),
    [flagged, vettingFilters]
  );

  return {
    flagged,
    cleared,
    flatFlaggedWithCategory,
  };
}

export default useGetVettingCategories;
