import React, {useMemo} from 'react';
import {
  designSystemToken,
  Headline,
  Icon,
  Label,
} from '@lightricks/react-design-system';
import {IconName} from '@lightricks/react-design-system/dist/components/icon/iconNames';
import translate from '@/utils/translate';
import Divider from '@/components/divider';
import styles from './ProfileVettingItemsScanned.module.scss';

const TRANSLATION_PREFIX =
  'components.creator-vetting-report.components.profile-vetting-items-scanned';

const DEFAULT_LOCALE = 'en-US';

interface ProfileVettingItemsScannedProps {
  totalItemsScanned: number;
  totalVideoMinutesScanned: number;
  totalImagesScanned: number;
}

interface ScannedItemProps {
  icon: IconName;
  title: string;
  subtitle: string;
}

function ScannedItem({icon, title, subtitle}: ScannedItemProps) {
  return (
    <div className={styles.scannedItem}>
      <Icon
        size="medium"
        appearance="neutral"
        name={icon}
        color={designSystemToken('semantic.fg.secondary')}
      />
      <Headline
        className={styles.title}
        size="md"
        color={designSystemToken('semantic.fg.secondary')}
      >
        {title}
      </Headline>
      <Label
        className={styles.subtitle}
        size="md"
        color={designSystemToken('semantic.fg.secondary')}
      >
        {subtitle}
      </Label>
    </div>
  );
}

function ProfileVettingItemsScanned(props: ProfileVettingItemsScannedProps) {
  const {totalItemsScanned, totalVideoMinutesScanned, totalImagesScanned} =
    props;

  const scannedItems = useMemo(() => {
    return [
      {
        id: 'video',
        icon: 'Navigation-VideoCamera-Line' as IconName,
        title: totalVideoMinutesScanned.toLocaleString(DEFAULT_LOCALE),
        subtitle: translate(`${TRANSLATION_PREFIX}.mins`),
      },
      {
        id: 'image',
        icon: 'Navigation-Photos-Light' as IconName,
        title: totalImagesScanned.toLocaleString(DEFAULT_LOCALE),
        subtitle: translate(`${TRANSLATION_PREFIX}.images`),
      },
      {
        id: 'text',
        icon: 'Features-Text' as IconName,
        title: translate(`${TRANSLATION_PREFIX}.all-text`),
        subtitle: '',
      },
    ].filter((item) => item.title !== '0');
  }, [totalVideoMinutesScanned, totalImagesScanned]);

  return (
    <div className={styles.profileVettingItemsScannedContainer}>
      <div className={styles.header}>
        <Headline
          className={styles.title}
          size="xl"
          color={designSystemToken('semantic.fg.primary')}
        >
          {totalItemsScanned.toLocaleString()}
        </Headline>
        <Label
          className={styles.subtitle}
          size="lg"
          color={designSystemToken('semantic.fg.primary')}
        >
          {translate(`${TRANSLATION_PREFIX}.content-scanned`)}
        </Label>
      </div>
      <div className={styles.itemsScanned}>
        {scannedItems.map((scannedItem, index) => (
          <div key={scannedItem.id} className={styles.itemsScannedContainer}>
            <ScannedItem {...scannedItem} />
            {index < scannedItems.length - 1 ? (
              <Divider margin={8} vertical height="60%" />
            ) : null}
          </div>
        ))}
      </div>
    </div>
  );
}

export default ProfileVettingItemsScanned;
