import React, {useState} from 'react';
import {Menu} from '@mui/material';
import styles from './DateRangePicker.module.scss';
import DateRangeElement from './components/date-range';
import {DateRange} from './types';

export interface DateRangePickerProps {
  testID?: string;
  initialDateRange?: DateRange;
  minDate?: Date | string;
  maxDate?: Date | string;
  onChange: (dateRange: DateRange) => void;
  renderInput?: (isOpen: boolean) => React.ReactNode;
}

function DateRangePicker(props: DateRangePickerProps) {
  const {testID = 'date-range-picker', renderInput, onChange} = props;
  const [isDirty, setIsDirty] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const close = () => setAnchorEl(null);

  const handleSubmit = (dateRange: DateRange) => {
    onChange(dateRange);
    handleClose();
  };

  const handleClose = (
    event?: React.MouseEvent<HTMLButtonElement> | object,
    reason?: 'backdropClick' | 'escapeKeyDown'
  ) => {
    if (
      !event ||
      reason === 'escapeKeyDown' ||
      (reason === 'backdropClick' && !isDirty) ||
      ((event as React.MouseEvent<HTMLButtonElement>)?.type === 'click' &&
        !reason)
    ) {
      close();
      setIsDirty(false);
    }
  };

  return (
    <div className={styles.dateRangePickerContainer}>
      <div
        data-testid={`${testID}--input`}
        onClick={({currentTarget}) => setAnchorEl(currentTarget)}
      >
        {renderInput?.(!!anchorEl)}
      </div>
      <Menu
        elevation={0}
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={handleClose}
        transformOrigin={{
          vertical: -10,
          horizontal: 'center',
        }}
        sx={{
          '> .MuiPaper-root': {
            borderRadius: '16px',
            boxShadow:
              '0px 4px 12px -2px rgba(24, 39, 75, 0.08), 0px 0px 4px 0px rgba(24, 39, 75, 0.02)',
          },
        }}
      >
        <DateRangeElement
          {...props}
          testID={testID}
          onClose={handleClose}
          onSubmit={handleSubmit}
          setIsDirty={setIsDirty}
        />
      </Menu>
    </div>
  );
}

export default DateRangePicker;
