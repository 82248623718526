import {get} from 'lodash';
import React, {useState} from 'react';
import {designSystemToken, Label} from '@lightricks/react-design-system';
import translate from '@/utils/translate';
import {PostAnalysis} from '@/types/creatorSafetyReport';
import Conditional from '@/components/conditional';
import {FlagWithCategory} from '@/components/creator-vetting-report/hooks/use-get-vetting-categories/useGetVettingCategories';
import getSeverityFromFlagScore from '@/components/creator-vetting-report/utils/getSeverityFromFlagScore';
import Divider from '@/components/divider';
import useParams from '@/hooks/use-params';
import useCreatorVettingReportStore, {
  creatorVettingReportActions,
} from '@/stores/creatorVettingReportStore';
import styles from './ProfileVettingFlaggedContent.module.scss';

const TRANSLATION_PREFIX =
  'components.creator-vetting-report.components.profile-vetting-flagged-content';

interface ProfileVettingFlaggedContentProps {
  flaggedContent: FlagWithCategory[];
}

function SeverityIndicator(props: {score: number}) {
  const {score} = props;
  const severity = getSeverityFromFlagScore(score);
  return <div className={`${styles.severityIndicator} ${styles[severity]}`} />;
}

function FlaggedContentItemPost(props: {
  flag: string;
  post: PostAnalysis;
  hoveredPostId?: string | null;
  remainingPosts: number;
  onClick: () => void;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
}) {
  const {
    flag,
    post,
    hoveredPostId,
    remainingPosts,
    onClick,
    onMouseEnter,
    onMouseLeave,
  } = props;

  const flagScore = get(
    post,
    `mediaAnalysisResult.brandSafetyAnalysis.${flag}.score`,
    0
  );

  return (
    <div className={styles.postContainer}>
      <div
        className={`${styles.post} ${
          hoveredPostId === post.id ? styles.hover : ''
        }`}
        onClick={onClick}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        {remainingPosts > 0 ? (
          <div className={styles.overlay}>
            <Label size="lg" color={designSystemToken('semantic.fg.white')}>
              +{remainingPosts}
            </Label>
          </div>
        ) : null}
        <img src={post.mediaAnalysisResult.metadata.url} alt="post" />
      </div>
      {remainingPosts === 0 ? <SeverityIndicator score={flagScore} /> : null}
    </div>
  );
}

function FlaggedContentItemPosts(props: {posts: PostAnalysis[]; flag: string}) {
  const {posts, flag} = props;
  const {reportId} = useParams();
  const creatorVettingReportState = useCreatorVettingReportStore();
  const report = creatorVettingReportState.reports[reportId];

  const [seeAll, setSeeAll] = useState(report?.seeAllFlagPosts[flag] || false);
  const displayedPosts = seeAll ? posts : posts.slice(0, 5);
  const remainingPosts = posts.length - displayedPosts.length;

  return (
    <div className={styles.postsContainer}>
      <div className={styles.posts}>
        {displayedPosts.map((post, index) => (
          <FlaggedContentItemPost
            key={post.id}
            flag={flag}
            post={post}
            hoveredPostId={report?.hoveredPostId}
            remainingPosts={
              index === displayedPosts.length - 1 && remainingPosts > 0
                ? remainingPosts
                : 0
            }
            onClick={() => {
              if (index === displayedPosts.length - 1 && remainingPosts > 0) {
                setSeeAll(true);
                creatorVettingReportActions.setSeeAllFlagPosts(
                  reportId,
                  flag,
                  true
                );
              } else {
                // TODO: open modal with post
              }
            }}
            onMouseEnter={() =>
              creatorVettingReportActions.setHoveredPostId(reportId, post.id)
            }
            onMouseLeave={() =>
              creatorVettingReportActions.setHoveredPostId(reportId, null)
            }
          />
        ))}
      </div>
      <Conditional condition={seeAll && posts.length > 5}>
        <Label
          className={styles.seeLess}
          size="md"
          color={designSystemToken('semantic.fg.secondary')}
          onClick={() => {
            setSeeAll(false);
            creatorVettingReportActions.setSeeAllFlagPosts(
              reportId,
              flag,
              false
            );
          }}
        >
          {translate(`${TRANSLATION_PREFIX}.see-less`)}
        </Label>
      </Conditional>
    </div>
  );
}

function Header(props: {category: string; flag: string; postsAmount: number}) {
  const {category, flag, postsAmount} = props;
  return (
    <div className={styles.header}>
      <div className={styles.category}>
        <Label size="sm" color={designSystemToken('semantic.fg.primary')}>
          {category}
        </Label>
      </div>
      <div className={styles.flag}>
        <Label size="lg" color={designSystemToken('semantic.fg.primary')}>
          {flag}
        </Label>
        <Label size="lg" color={designSystemToken('semantic.fg.secondary')}>
          ({postsAmount})
        </Label>
      </div>
    </div>
  );
}

function FlaggedContentItem(props: {item: FlagWithCategory; isFirst: boolean}) {
  const {item, isFirst} = props;

  return (
    <div className={styles.flaggedContentItemContainer}>
      {isFirst && <Divider margin={0} />}
      <Header
        category={item.category.name}
        flag={item.name}
        postsAmount={item.postsAmount}
      />
      <FlaggedContentItemPosts posts={item.posts} flag={item.id} />
      <Divider margin={0} />
    </div>
  );
}

function ProfileVettingFlaggedContent(
  props: ProfileVettingFlaggedContentProps
) {
  const {flaggedContent} = props;
  return (
    <div>
      {flaggedContent.map((item, index) => (
        <FlaggedContentItem key={item.id} item={item} isFirst={index === 0} />
      ))}
    </div>
  );
}

export default ProfileVettingFlaggedContent;
