import {CreatorSafetyReport} from '@/types/creatorSafetyReport';
import vettingFetchers from '@/api/fetchers/vetting';
import useDataQuery from '@/hooks/use-data-query';

function useVettingReportQuery({
  brandId,
  creatorId,
  reportId,
}: {
  brandId: string;
  creatorId: string;
  reportId: string;
}) {
  return useDataQuery<CreatorSafetyReport>({
    queryKey: ['vettingReport', creatorId, reportId],
    queryFn: vettingFetchers.report,
    enabled: !!brandId && !!creatorId && !!reportId,
    refetchOnWindowFocus: false,
    cacheTime: Infinity,
    staleTime: Infinity,
    meta: {
      brandId,
      creatorId,
      reportId,
    },
  });
}

export default useVettingReportQuery;
