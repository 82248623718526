/**
 * supported networks array (extracted from ember/config/environment.js)
 * the list is sorted according to the popularity
 */
export const getSupportedNetworks = () => {
  return [
    'instagram',
    'tiktok',
    'tiktok_v2',
    'tiktok_creator_marketplace',
    'youtube',
    'facebook',
    'pinterest',
    'twitter',
  ];
};

export const getTiktokNetworksPriority = () => {
  return ['tiktok_v2', 'tiktok'];
};

export const noOp = () => {};
