import React from 'react';
import translate from '@/utils/translate';
import NavLink from '@/components/nav-link';
import {MenuIconBrandedContent} from '@/components/unusual-icons';
import useAgencyQuery from '@/hooks/queries/use-agency-query';
import useDashboardUserCurrentBrandQuery from '@/hooks/queries/use-dashboard-user-current-brand-query';
import useBrandId from '@/hooks/use-brand-id';
import useDashboardUserPermissions from '@/hooks/use-dashboard-user-permissions';
import useToken from '@/hooks/use-token';
import styles from './MenuItem.module.scss';
import {MenuItemProps} from './MenuItemProps';

function MenuItem(props: MenuItemProps) {
  const {
    id,
    Icon,
    iconProps = {},
    localeLabelKey,
    link,
    requiredPermissionsToDisplay,
    requiredPermissionsToUnlock,
    customIsDisplayedFunc,
    showUnreadIndicatorFunc,
    showLabel = true,
    user,
  } = props;

  const menuItemId = `menu-item--${id}`;
  const menuItemLink = link;

  const {isAuthenticated} = useToken(true);
  const brandId = useBrandId();
  const {currentBrand}: any = useAgencyQuery({brandId});
  const {dashboardUserCurrentBrand}: any = useDashboardUserCurrentBrandQuery({
    brandId,
    enabled: isAuthenticated,
  });

  const displayPermissions = useDashboardUserPermissions(
    requiredPermissionsToDisplay,
    user?.permissions
  );

  const unlockPermissions = useDashboardUserPermissions(
    requiredPermissionsToUnlock,
    user?.permissions
  );
  const customIsDisplayed = customIsDisplayedFunc
    ? customIsDisplayedFunc({...props, currentBrand})
    : true;

  const showUnreadIndicator = showUnreadIndicatorFunc
    ? showUnreadIndicatorFunc(dashboardUserCurrentBrand)
    : false;

  const isDisplayed =
    Object.values(displayPermissions).every(Boolean) && customIsDisplayed;
  const isUnlocked = Object.values(unlockPermissions).every(Boolean);

  if (!isDisplayed) {
    return null;
  }

  const linkLabel = translate(localeLabelKey);

  return (
    <div>
      <NavLink
        id={menuItemId}
        to={menuItemLink}
        testID={menuItemId}
        className={({isActive}) =>
          `${styles.container} ${isActive ? 'active' : ''} ${
            isUnlocked ? 'unlocked' : 'locked'
          } ${menuItemId}`
        }
      >
        {showUnreadIndicator ? (
          <span
            data-testid={`${menuItemId}--indicator`}
            className={styles.unreadIndicator}
          />
        ) : null}
        <div className={styles.icon}>
          {id === 'branded-content' ? (
            <MenuIconBrandedContent />
          ) : (
            <Icon {...iconProps} />
          )}
        </div>
        {showLabel && <span className={styles.label}>{linkLabel}</span>}
      </NavLink>
    </div>
  );
}

export default MenuItem;
