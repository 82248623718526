// TODO: get the list of all the categories + their mapping to the flags
export const VETTING_CATEGORY = {
  ADULT: 'ADULT',
  VIOLENCE: 'VIOLENCE',
  SUBSTANCE: 'SUBSTANCE',
  OFFENSIVE: 'OFFENSIVE',
  EVIL_STUFF: 'EVIL_STUFF',
  SENSITIVE: 'SENSITIVE',
  SELF_HARM: 'SELF_HARM',
  DISTURBING: 'DISTURBING',
  RACISM: 'RACISM',
};

export const VETTING_FLAGS_TO_CATEGORY_MAPPING = {
  violence: VETTING_CATEGORY.VIOLENCE,
  offensive: VETTING_CATEGORY.OFFENSIVE,
  racial: VETTING_CATEGORY.RACISM,
  indelicate: VETTING_CATEGORY.OFFENSIVE,
  adult: VETTING_CATEGORY.ADULT,
  drugs: VETTING_CATEGORY.SUBSTANCE,
  gambling: VETTING_CATEGORY.SENSITIVE,
  weapons: VETTING_CATEGORY.EVIL_STUFF,
  obscenity: VETTING_CATEGORY.DISTURBING,
  terrorism: VETTING_CATEGORY.EVIL_STUFF,
  bullying: VETTING_CATEGORY.EVIL_STUFF,
  profanity: VETTING_CATEGORY.SENSITIVE,
  discrimination: VETTING_CATEGORY.SENSITIVE,
  smoking: VETTING_CATEGORY.SUBSTANCE,
  vaping: VETTING_CATEGORY.SUBSTANCE,
  alcohol: VETTING_CATEGORY.SUBSTANCE,
  pranking: VETTING_CATEGORY.EVIL_STUFF,
  politicalContent: VETTING_CATEGORY.SENSITIVE,
  religiousContent: VETTING_CATEGORY.SENSITIVE,
  graphicContent: VETTING_CATEGORY.DISTURBING,
  sensitiveSocialIssues: VETTING_CATEGORY.SENSITIVE,
  animalCruelty: VETTING_CATEGORY.EVIL_STUFF,
  hateSpeech: VETTING_CATEGORY.SENSITIVE,
  sexualContent: VETTING_CATEGORY.ADULT,
  childExploitation: VETTING_CATEGORY.EVIL_STUFF,
  selfHarm: VETTING_CATEGORY.SELF_HARM,
  illegalActivities: VETTING_CATEGORY.EVIL_STUFF,
  foulLanguage: VETTING_CATEGORY.OFFENSIVE,
} as {[key: string]: string};

export enum Severity {
  ALL = 'ALL',
  HIGH = 'HIGH',
  MEDIUM = 'MEDIUM',
  LOW = 'LOW',
}

export const SEVERITY_THRESHOLDS = {
  [Severity.HIGH]: 1,
  [Severity.MEDIUM]: 0.66,
  [Severity.LOW]: 0.33,
};
