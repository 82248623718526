import React, {useEffect} from 'react';
import {designSystemToken, Headline} from '@lightricks/react-design-system';
import translate from '@/utils/translate';
import {CreatorSafetyReport} from '@/types/creatorSafetyReport';
import Conditional from '@/components/conditional';
import ProfileVettingActions from '@/components/creator-vetting-report/components/profile-vetting-actions';
import ProfileVettingFlaggedContent from '@/components/creator-vetting-report/components/profile-vetting-flagged-content';
import ProfileVettingItemsFlagged from '@/components/creator-vetting-report/components/profile-vetting-items-flagged';
import useGetVettingCategories from '@/components/creator-vetting-report/hooks/use-get-vetting-categories';
import useGetVettingFilters from '@/components/creator-vetting-report/hooks/use-get-vetting-filters';
import Divider from '@/components/divider';
import ProfileLink from '@/components/profile-link';
import useParams from '@/hooks/use-params';
import {creatorVettingReportActions} from '@/stores/creatorVettingReportStore';
import styles from './CreatorVettingReport.module.scss';
import ProfileVettingBreadcrumbs from './components/profile-vetting-breadcrumbs';
import ProfileVettingInfo from './components/profile-vetting-info';
import ProfileVettingItemsScanned from './components/profile-vetting-items-scanned';
import ProfileVettingSummary from './components/profile-vetting-summary';
import ProfileVettingTracking from './components/profile-vetting-tracking';
import ScannedPlatforms from './components/scanned-platforms';
import {Severity} from './constants';

const TRANSLATION_PREFIX = 'components.creator-vetting-report';

export interface CreatorVettingReportProps {
  testID?: string;
  vettingReport: CreatorSafetyReport; // TODO: define correct type
  showBackButton?: boolean;
  showProfileInfo?: boolean;
}

function SectionTitle(props: {title: string; actions?: React.ReactNode}) {
  const {title, actions} = props;
  return (
    <div className={styles.sectionTitle}>
      <Headline size="xl">{title}</Headline>
      <div className={styles.actions}>{actions}</div>
    </div>
  );
}

function CreatorVettingReport(props: CreatorVettingReportProps) {
  const {
    testID = 'creator-vetting-report',
    vettingReport,
    showBackButton = true,
    showProfileInfo = true,
  } = props;

  const {reportId} = useParams();

  const vettingFilters = useGetVettingFilters(vettingReport.timeRange);

  const {postAnalysesStats} = vettingReport;
  const vettingCategories = useGetVettingCategories(
    vettingReport.flaggedPostAnalyses,
    {
      timeRange: {
        start: vettingFilters.dateRange.startDate,
        end: vettingFilters.dateRange.endDate,
      },
      severity: vettingFilters.severity,
    }
  );

  const [liveTrackingProxy, setLiveTrackingProxy] = React.useState(
    vettingReport.liveTracking
  );

  const [vettingStatusProxy, setVettingStatusProxy] = React.useState(
    vettingReport.status
  );

  const handleOnTrackingChanged = (isTracking: boolean) => {
    setLiveTrackingProxy(isTracking);
  };

  const handleOnVettingStatusChange = (status: string) => {
    setVettingStatusProxy(status);
  };

  useEffect(() => {
    creatorVettingReportActions.initializeReportSettings(reportId);
  }, []);

  return (
    <div className={styles.creatorVettingReportContainer} data-testid={testID}>
      <div className={styles.creatorVettingReportContent}>
        <Conditional condition={showBackButton}>
          <ProfileVettingBreadcrumbs />
        </Conditional>
        <div className={styles.creatorVettingReportWrapper}>
          <div className={styles.sideBarContainer}>
            <ProfileVettingInfo
              profileImageUrl={vettingReport.thumbnailUrl}
              profileDisplayName={vettingReport.creatorName}
              showProfileInfo={showProfileInfo}
              vettingStatus={vettingStatusProxy}
              onVettingStatusChanged={handleOnVettingStatusChange}
            />
            <span>
              <Divider
                margin={0}
                color={designSystemToken('semantic.bg.neutral-inverse')}
              />
            </span>
            <ProfileVettingTracking
              isTracking={liveTrackingProxy}
              onTrackingChanged={handleOnTrackingChanged}
              timeRange={vettingReport.timeRange}
            />
            <span>
              <Divider
                margin={0}
                color={designSystemToken('semantic.bg.neutral-inverse')}
              />
            </span>
            <ScannedPlatforms platforms={vettingReport.platforms} />
            <Conditional condition={showProfileInfo}>
              <div className={styles.profileLinkContainer}>
                <ProfileLink profileId={vettingReport.creatorId} />
              </div>
            </Conditional>
          </div>
          <div className={styles.contentContainer}>
            <div className={styles.vettingSummaryContainer}>
              <ProfileVettingItemsScanned
                totalItemsScanned={postAnalysesStats.totalPosts}
                totalVideoMinutesScanned={
                  postAnalysesStats.totalVideoDurationInSec
                    ? Math.round(postAnalysesStats.totalVideoDurationInSec / 60)
                    : 0
                }
                totalImagesScanned={postAnalysesStats.totalImages}
              />
              <ProfileVettingItemsFlagged
                totalItemsScanned={postAnalysesStats.totalPosts}
                totalItemsFlagged={vettingReport.flaggedPostAnalyses.length}
                vettingCategories={vettingCategories}
              />
              <ProfileVettingSummary summary={vettingReport.summary.summary} />
            </div>
            <div className={styles.onlineControversyContainer}>
              <SectionTitle
                title={translate(
                  `${TRANSLATION_PREFIX}.background-checks-title`
                )}
              />
              <div>TODO: Online Controversy</div>
            </div>
            <div className={styles.flaggedContentContainer}>
              <SectionTitle
                title={translate(`${TRANSLATION_PREFIX}.flagged-content-title`)}
                actions={
                  <ProfileVettingActions
                    vettingFilters={vettingFilters}
                    onDateRangeChange={(newDateRange) => {
                      if (newDateRange.startDate && newDateRange.endDate) {
                        creatorVettingReportActions.setReportTimeRangeFilter(
                          reportId,
                          {
                            start: newDateRange.startDate,
                            end: newDateRange.endDate,
                          }
                        );
                      }
                    }}
                    onSeverityChange={(severity) => {
                      creatorVettingReportActions.setReportSeverityFilter(
                        reportId,
                        severity.id as Severity
                      );
                    }}
                  />
                }
              />
              <ProfileVettingFlaggedContent
                flaggedContent={vettingCategories.flatFlaggedWithCategory}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CreatorVettingReport;
