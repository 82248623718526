import React, {useState} from 'react';
import {Menu, MenuItem} from '@mui/material';
import {
  Body,
  Button,
  Checkbox,
  designSystemToken,
  Headline,
  Icon,
} from '@lightricks/react-design-system';
import translate from '@/utils/translate';
import Conditional from '@/components/conditional';
import Divider from '@/components/divider';
import styles from './TrackingButton.module.scss';

const TRANSLATION_PREFIX =
  'components.creator-vetting-report.components.tracking-button';

const iconTracking = '/assets/svg/icon-tracking.svg';

enum TrackingState {
  ON = 'ON',
  OFF = 'OFF',
}

function TrackingMenuItem({
  value,
  title,
  subtitle,
  activeSelection,
  onSelected,
}: {
  value: TrackingState;
  title: string;
  subtitle: string;
  activeSelection: TrackingState;
  onSelected: (isTracking: TrackingState) => void;
}) {
  return (
    <MenuItem
      sx={{
        '&.MuiMenuItem-root': {
          borderRadius: '16px',
          margin: '8px',
        },
      }}
      onClick={() => onSelected(value)}
    >
      <div className={styles.trackingMenuItemContainer}>
        <div className={styles.trackingMenuItemInfo}>
          <Body size="lg" color={designSystemToken('semantic.fg.primary')}>
            {title}
          </Body>
          <Body
            size="sm"
            color={designSystemToken('semantic.fg.secondary')}
            className={styles.subtitle}
          >
            {subtitle}
          </Body>
        </div>
        <div className={styles.checkboxContainer}>
          <Checkbox radio checked={activeSelection === value} />
        </div>
      </div>
    </MenuItem>
  );
}

function TrackingButton(props: {
  isTracking?: boolean;
  onTrackingChanged: (isTracking: boolean) => void;
}) {
  const {isTracking, onTrackingChanged} = props;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [activeSelection, setActiveSelection] = useState<TrackingState>(
    isTracking ? TrackingState.ON : TrackingState.OFF
  );

  const handleOnMenuClosed = () => {
    if (activeSelection === TrackingState.ON && !isTracking) {
      onTrackingChanged(true);
    } else if (activeSelection === TrackingState.OFF && isTracking) {
      onTrackingChanged(false);
    }
    setAnchorEl(null);
  };

  return (
    <div className={styles.trackingButtonContainer}>
      <Button
        appearance="neutral"
        mode="plain"
        size="small"
        className={styles.trackingButton}
        onClick={({currentTarget}) => setAnchorEl(currentTarget)}
      >
        <Headline className={styles.trackingButtonLabel} size="sm">
          {translate(
            `${TRANSLATION_PREFIX}.${
              isTracking ? 'tracking-on' : 'tracking-off'
            }`
          )}
          <span className={`${styles.icon} ${anchorEl ? styles.iconOpen : ''}`}>
            <Icon
              appearance="neutral"
              size="small"
              name="Actions-Arrow-Down-Small"
            />
          </span>
        </Headline>
      </Button>
      <Conditional condition={!!isTracking}>
        <img src={iconTracking} alt="tracking" />
      </Conditional>
      <Menu
        open={!!anchorEl}
        onClose={handleOnMenuClosed}
        anchorEl={anchorEl}
        sx={{
          '& .MuiPaper-root': {
            marginTop: '8px',
            borderRadius: '16px',
            width: '300px',
            paddingInline: '8px',
          },
        }}
      >
        <TrackingMenuItem
          value={TrackingState.ON}
          title={translate(`${TRANSLATION_PREFIX}.options.on`)}
          subtitle={translate(`${TRANSLATION_PREFIX}.options.on-description`)}
          activeSelection={activeSelection}
          onSelected={setActiveSelection}
        />
        <Divider margin={0} />
        <TrackingMenuItem
          value={TrackingState.OFF}
          title={translate(`${TRANSLATION_PREFIX}.options.off`)}
          subtitle={translate(`${TRANSLATION_PREFIX}.options.off-description`)}
          activeSelection={activeSelection}
          onSelected={setActiveSelection}
        />
      </Menu>
    </div>
  );
}

export default TrackingButton;
