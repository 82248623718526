import {SortingState} from '@tanstack/table-core';
import React from 'react';
import CreatorGroupMembership, {
  MembershipStatus,
} from '@/types/creatorGroupMembership';
import creatorGroupsFetchers from '@/api/fetchers/creatorGroups';
import useInfiniteReactQuery from '@/hooks/use-infinite-react-query';

function getMembershipStatus(
  membership: CreatorGroupMembership
): MembershipStatus {
  let status: MembershipStatus = 'active';
  if (membership.removedAt) {
    status = 'removed';
  } else if (membership.pending === true) {
    status = 'pending';
  } else if (
    membership.profile === undefined ||
    membership.profile.isActive === false
  ) {
    status = 'deactivated';
  }

  return status;
}

type UseCreatorGroupsLabelsQueryParams = {
  creatorGroupId?: string;
  labelIds?: string[];
  q?: string | null;
  unlabeled?: boolean;
  sorting?: SortingState | null;
  refetchOnWindowFocus?: boolean;
};

const modelHelpers = (membership: CreatorGroupMembership) => ({
  status: getMembershipStatus(membership),
});

function useCreatorGroupsMembershipsQuery(
  params: UseCreatorGroupsLabelsQueryParams
) {
  const {
    creatorGroupId,
    labelIds,
    q,
    sorting,
    refetchOnWindowFocus = true,
  } = params;

  const response = useInfiniteReactQuery({
    queryKey: [
      'creatorGroupsMemberships',
      creatorGroupId,
      labelIds,
      q,
      sorting?.map((sort) => `${sort.id}-${sort.desc}`),
    ],
    queryFn: creatorGroupsFetchers.memberships,
    enabled: !!creatorGroupId,
    meta: {creatorGroupId, labelIds, q, sorting},
    refetchOnWindowFocus,
    getNextPageParam: (lastPage) => {
      if (lastPage?.meta?.pagination.currentPage === undefined) return null;

      return lastPage.meta.pagination.currentPage + 1;
    },
  });

  const membershipsResponse: any = React.useMemo(() => {
    const {pages = []} = response?.data || {pages: []};
    const [firstPageData] = pages;

    const responseData = firstPageData
      ? {
          data: pages.flatMap((pageData) => pageData.data),
          loadedPages: pages.length,
        }
      : null;
    return responseData || {};
  }, [response.data]);

  return {
    ...response,
    data: membershipsResponse?.data?.map(
      (membership: CreatorGroupMembership) => ({
        ...membership,
        ...modelHelpers(membership),
      })
    ),
  };
}

export default useCreatorGroupsMembershipsQuery;
