import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import isoWeek from 'dayjs/plugin/isoWeek';
import minMax from 'dayjs/plugin/minMax';
import {DateRange} from './types';

dayjs.extend(isBetween);
dayjs.extend(minMax);
dayjs.extend(isoWeek);

export const chunks = <T>(array: ReadonlyArray<T>, size: number): T[][] =>
  Array.from({length: Math.ceil(array.length / size)}, (_v, i) =>
    array.slice(i * size, i * size + size)
  );

// Date
export const getDaysInMonth = (date: Date) => {
  const dayjsDate = dayjs(date);

  const startWeek = dayjsDate.startOf('month').startOf('isoWeek');
  const endWeek = dayjsDate.endOf('month').endOf('isoWeek');
  const days = [];

  for (
    let curr = startWeek;
    curr.isBefore(endWeek);
    curr = curr.add(1, 'day')
  ) {
    days.push(curr.toDate());
  }

  return days;
};

export const isStartOfRange = ({startDate}: DateRange, day: Date) =>
  startDate ? dayjs(day).isSame(dayjs(startDate), 'day') : false;

export const isEndOfRange = ({endDate}: DateRange, day: Date) =>
  endDate ? dayjs(day).isSame(dayjs(endDate), 'day') : false;

export const isStartOfWeek = (date: Date) => dayjs(date).isoWeekday() === 1;
export const isEndOfWeek = (date: Date) => dayjs(date).isoWeekday() === 7;

export const isStartOfMonth = (date: Date) => dayjs(date).date() === 1;
export const isEndOfMonth = (date: Date) =>
  dayjs(date).date() === dayjs(date).daysInMonth();

export const inDateRange = ({startDate, endDate}: DateRange, day: Date) =>
  startDate && endDate
    ? dayjs(day).isBetween(dayjs(startDate), dayjs(endDate), 'day', '[]')
    : false;

export const isRangeSameDay = ({startDate, endDate}: DateRange) =>
  startDate && endDate ? dayjs(startDate).isSame(dayjs(endDate), 'day') : false;

type Falsy = false | null | undefined | 0 | '';

export const parseOptionalDate = (
  date: Date | string | Falsy,
  defaultValue: Date
) => {
  if (date) {
    const parsed = dayjs(date);
    if (parsed.isValid()) return parsed.toDate();
  }
  return defaultValue;
};

export const getValidatedMonths = (
  range: DateRange,
  minDate: Date,
  maxDate: Date
) => {
  const {startDate, endDate} = range;
  if (startDate && endDate) {
    const newStart = dayjs.max(dayjs(startDate), dayjs(minDate)) as dayjs.Dayjs;
    const newEnd = dayjs.min(dayjs(endDate), dayjs(maxDate)) as dayjs.Dayjs;

    return [
      newStart.toDate(),
      newStart.isSame(newEnd, 'month')
        ? newStart.add(1, 'month').toDate()
        : newEnd.toDate(),
    ];
  }
  return [startDate, endDate];
};
