import React from 'react';
import {
  Body,
  designSystemToken,
  Headline,
} from '@lightricks/react-design-system';
import translate from '@/utils/translate';
import styles from './ProfileVettingSummary.module.scss';

const TRANSLATION_PREFIX =
  'components.creator-vetting-report.components.profile-vetting-summary';

interface ProfileVettingSummaryProps {
  summary: string;
}

function ProfileVettingSummary({summary}: ProfileVettingSummaryProps) {
  return (
    <div className={styles.profileVettingSummaryContainer}>
      <Headline size="md" color={designSystemToken('semantic.fg.primary')}>
        {translate(`${TRANSLATION_PREFIX}.vetting-summary`)}
      </Headline>
      <Body size="md" color={designSystemToken('semantic.fg.primary')}>
        {summary}
      </Body>
    </div>
  );
}

export default ProfileVettingSummary;
