import dayjs from 'dayjs';
import React from 'react';
import {
  designSystemToken,
  Headline,
  Label,
} from '@lightricks/react-design-system';
import getFormattedPastDate from '@/utils/getFormattedPastDate';
import translate from '@/utils/translate';
import {DAY_MONTH_YEAR_FORMAT} from '@/config/timeFormats';
import {TimeRange} from '@/types/timeRange';
import TrackingButton from '@/components/creator-vetting-report/components/tracking-button';
import styles from './ProfileVettingTracking.module.scss';

const TRANSLATION_PREFIX =
  'components.creator-vetting-report.components.profile-vetting-tracking';

interface ProfileVettingTrackingProps {
  isTracking?: boolean;
  onTrackingChanged: (isTracking: boolean) => void;
  timeRange: TimeRange;
}

function Section({
  title,
  children,
}: {
  title: string;
  children: React.ReactNode;
}) {
  return (
    <div className={styles.section}>
      <Label size="sm" color={designSystemToken('semantic.fg.primary')}>
        {title}
      </Label>
      <div className={styles.sectionContent}>{children}</div>
    </div>
  );
}

function Updated({timeRange}: {timeRange: TimeRange}) {
  const {start, end} = timeRange;
  return (
    <div className={styles.updatedContainer}>
      <Headline size="sm">{getFormattedPastDate(end)}</Headline>
      <Label
        className={styles.label}
        size="xs"
        color={designSystemToken('semantic.fg.secondary')}
      >
        {translate(`${TRANSLATION_PREFIX}.since-date`, {
          date: dayjs(start).format(DAY_MONTH_YEAR_FORMAT),
        })}
      </Label>
    </div>
  );
}

function ProfileVettingTracking(props: ProfileVettingTrackingProps) {
  const {isTracking, onTrackingChanged, timeRange} = props;
  return (
    <div className={styles.profileVettingTrackingContainer}>
      <Section title={translate(`${TRANSLATION_PREFIX}.updated`)}>
        <Updated timeRange={timeRange} />
      </Section>
      <Section title={translate(`${TRANSLATION_PREFIX}.live-tracking`)}>
        <TrackingButton
          isTracking={isTracking}
          onTrackingChanged={onTrackingChanged}
        />
      </Section>
    </div>
  );
}

export default ProfileVettingTracking;
