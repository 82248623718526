import React, {useState, useEffect, useRef} from 'react';
import {useSearchParams} from 'react-router-dom';
import {v4 as uuid} from 'uuid';
import {useShallow} from 'zustand/react/shallow';
import {Headline, Button, Icon, Banner} from '@lightricks/react-design-system';
import AnalyticsService, {
  eventNames,
} from '@/services/analytics/AnalyticsService';
import {buttonNames, screenNames} from '@/lib/delta/deltaConstants.js';
import translate from '@/utils/translate';
import usePreferredCreatorGroupMemberships from '@/views/creators/lists/hooks/usePreferredCreatorGroupMemberships';
import getCreatorIdsForAnalytics from '@/views/creators/utils/getCreatorIdsForAnalytics';
import ListDrawer from '@/components/list-drawer';
import Page from '@/components/page';
import SearchInput from '@/components/search-input';
import useBrandId from '@/hooks/use-brand-id';
import useListCreatorsStore, {
  listCreatorsActions,
} from '@/stores/list-creators/listCreatorsStore';
import styles from './CreatorLists.module.scss';
import EmptyState from './EmptyState';
import InviteExternalCreatorsModal from './InviteExternalCreatorsModal';
import ListCreatorsTable from './ListCreatorsTable';
import ListAction from './components/ListAction';
import ListCreatorsActionBar from './components/ListCreatorsActionBar';
import usePreferredCreatorGroupLabels from './hooks/usePreferredCreatorGroupLabels';

const TRANSLATION_PREFIX = 'views.list-creators';

function useSyncSelectedListsFromSearchParams() {
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const selectedLists = searchParams.get('selectedLists');
    if (selectedLists) {
      listCreatorsActions.selectMultipleLists(selectedLists.split(','));
    }
  }, []);
}

function useSyncSelectedListsToSearchParams() {
  const [searchParams, setSearchParams] = useSearchParams();
  const selectedLists = [
    ...useListCreatorsStore(
      useShallow((state) => state.selectedLists)
    ).values(),
  ].join(',');

  useEffect(() => {
    if (selectedLists) {
      searchParams.set('selectedLists', selectedLists);
    } else {
      searchParams.delete('selectedLists');
    }
    setSearchParams(searchParams);
  }, [selectedLists]);
}

function useResetOnBrandChange(brandId: string) {
  useEffect(() => {
    listCreatorsActions.reset();
  }, [brandId]);
}

function CreatorLists() {
  useSyncSelectedListsFromSearchParams();
  useSyncSelectedListsToSearchParams();

  const {labels} = usePreferredCreatorGroupLabels();
  const brandId = useBrandId();
  useResetOnBrandChange(brandId);

  const {memberships, isLoading, isFetching, fetchNextPage} =
    usePreferredCreatorGroupMemberships();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [bannerVisible, setBannerVisible] = useState(false);
  const [bannerMessage, setBannerMessage] = useState('');

  const eventSent = useRef(false);

  const handleOpenModal = () => {
    setIsModalOpen(true);

    AnalyticsService.dispatchEvent(eventNames.BUTTON_PRESSED, {
      button_name: buttonNames.MY_CREATORS.INVITE_EXTERNAL_CREATORS,
      screen_name: screenNames.MY_CREATORS.MY_CREATORS,
      flow_id: '',
      flow_name: '',
      triggered_flow_id: '',
      triggered_flow_name: '',
      screen_presentation_id:
        AnalyticsService.getActiveScreenPresented().screen_presentation_id,
      campaign_id: '',
      creator_id: '',
      tab: '',
    });
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleInviteSuccess = (message: string) => {
    setBannerMessage(message);
    setBannerVisible(true);
  };

  const renderEmptyState = () => {
    if (labels.length === 0 && !isLoading) {
      return (
        <EmptyState
          message={translate(`${TRANSLATION_PREFIX}.empty-state.no-lists`)}
          description={translate(
            `${TRANSLATION_PREFIX}.empty-state.no-lists-description`
          )}
          buttonText={translate(`${TRANSLATION_PREFIX}.empty-state.button`)}
          buttonLink={`/search/?brandId=${brandId}`}
        />
      );
    }
    if (memberships.length === 0 && !isLoading) {
      return (
        <EmptyState
          message={translate(`${TRANSLATION_PREFIX}.empty-state.no-creators`)}
          description={translate(
            `${TRANSLATION_PREFIX}.empty-state.no-creators-description`
          )}
          buttonText={translate(`${TRANSLATION_PREFIX}.empty-state.button`)}
          buttonLink={`/search/?brandId=${brandId}`}
        />
      );
    }
    return null;
  };

  useEffect(() => {
    const screenPresentationId = uuid();
    if (memberships.length > 0 && !eventSent.current) {
      AnalyticsService.dispatchEvent(eventNames.MY_CREATORS_RESULTS_PRESENTED, {
        screen_presentation_id: screenPresentationId,
        creator_ids: getCreatorIdsForAnalytics(memberships),
        result_source: 'my_creators',
      });

      eventSent.current = true;
    }
  }, [memberships]);

  return (
    <Page
      id="creator-lists"
      className={styles.container}
      childrenClassName={styles.content}
    >
      {bannerVisible && (
        <Banner
          isOpen={bannerVisible}
          title={bannerMessage}
          severity="success"
          mode="light"
          onActionClick={() => setBannerVisible(false)}
          icon={
            <Icon
              size="medium"
              appearance="brand"
              name="Actions-Patch-Accept-Line"
            />
          }
          className={styles.banner}
        />
      )}
      <div className={styles.topBar}>
        <div className={styles.topBarLeft}>
          <Headline size="lg">
            {translate(`${TRANSLATION_PREFIX}.title`)}
          </Headline>
        </div>
        <div className={styles.topBarRight}>
          <Button
            appearance="neutral"
            mode="tinted"
            size="medium"
            icon={
              <Icon size="small" appearance="neutral" name="Navigation-Mail" />
            }
            onClick={handleOpenModal}
          >
            {translate(`${TRANSLATION_PREFIX}.button.invite-external-creators`)}
          </Button>
          <SearchInput
            placeholder={translate(`${TRANSLATION_PREFIX}.search.placeholder`)}
            onSubmit={(val) => {
              listCreatorsActions.setTableFilterValue(val);
            }}
          />
        </div>
      </div>
      <div className={styles.main}>
        <ListDrawer labels={labels} />
        {renderEmptyState() || (
          <ListCreatorsActionBar>
            <ListCreatorsTable
              data={memberships}
              isLoading={isLoading}
              isFetching={isFetching}
              fetchNextPage={fetchNextPage}
            />
          </ListCreatorsActionBar>
        )}
      </div>
      <ListAction />
      <InviteExternalCreatorsModal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        onSuccess={handleInviteSuccess}
        listOptions={labels}
      />
    </Page>
  );
}

export default CreatorLists;
