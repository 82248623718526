import React, {useState} from 'react';
import {Avatar, Body, Label} from '@lightricks/react-design-system';
import {Asset} from '@/types/asset';
import Profile from '@/types/profile';
import Link from '@/components/link';
import MediaFileViewer from '@/components/media-file-viewer';
import ProfilePopup from '@/components/profile/ProfilePopup';
import useAvatarImageOrInitials from '@/hooks/use-avatar-image-or-initials';
import styles from './AssetCard.module.scss';

const TRANSLATION_PREFIX = 'components.asset-card';

function AssetCard(props: Asset) {
  const {
    id,
    downloadUrl,
    isAiContent,
    kind,
    thumbnailUrl,
    url,
    profile,
    campaign,
  } = props;
  return (
    <div className={styles.assetCardContainer}>
      <div className={styles.content} data-testid="asset-card">
        <div className={styles.imageWrapper}>
          <MediaFileViewer thumbnailUrl={thumbnailUrl} kind={kind} url={url} />
        </div>
        {profile && <ProfileInfo profile={profile} kind={kind} />}
        {campaign && (
          <CampaignLink
            id={campaign.id as string}
            campaignName={campaign.name}
          />
        )}
      </div>
    </div>
  );
}

type ProfileInfoProps = {
  profile: Profile;
  kind: 'photo' | 'video' | 'blog';
};

function ProfileInfo(props: ProfileInfoProps) {
  const {profile, kind} = props;
  const [isHovered, setIsHovered] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handleMouseEnter = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setAnchorEl(null);
    setIsHovered(false);
  };
  const {imageUrl: profileImageUrl, initials} = useAvatarImageOrInitials(
    profile.imageUrl,
    profile.displayName
  );
  return (
    <div key={profile.id} className={styles.profile}>
      <div
        className={styles.profileHoverContainer}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <Avatar
          className={styles.profileAvatar}
          size="sm"
          src={profileImageUrl}
          type={profileImageUrl ? 'picture' : 'placeholder'}
          initials={initials}
          variant="square"
          background="transparent"
        />
        <Label size="md">{profile.displayName}</Label>
        {profile && (
          <ProfilePopup
            isVisible={isHovered}
            profile={profile}
            anchorEl={anchorEl}
            onClose={handleMouseLeave}
            email={profile.email}
          />
        )}
      </div>
      <div className={`${styles.kind} ${styles[kind]}`} />
    </div>
  );
}

type CampaignInfoProps = {
  id: string;
  campaignName: string;
};

function CampaignLink({id, campaignName}: CampaignInfoProps) {
  return (
    <Link key={id} to={`/campaigns/${id}/content-downloads`} target="_blank">
      <Body size="md" className={styles.campaignLink}>
        {campaignName}
      </Body>
    </Link>
  );
}

export default AssetCard;
