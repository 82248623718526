import {produce} from 'immer';
import {WritableDraft} from 'immer/src/types/types-external';
import {create} from 'zustand';
import {devtools} from 'zustand/middleware';
import {DevtoolsOptions} from 'zustand/middleware/devtools';
import {immer} from 'zustand/middleware/immer';
import Storage from '@/utils/wrappers/Storage';
import {getEnv} from '@/config/environment';
import {
  BaseFilterOption,
  DynamicPlatformFilters,
  Filter,
  FiltersState,
  SingleUserInput,
  MultiUserInput,
  Range,
  RangeSelection,
  SingleSelection,
} from '@/types/models/search-creators/filter';
import {
  FilterIds,
  FilterOptionSelection,
  FiltersGroupId,
  PlatformFilterId,
  PlatformFilterIds,
  PlatformId,
  UpdatableFilterOptionSelection,
} from '@/types/models/search-creators/filterId';
import {
  FilterUpdate,
  LdaUpdate,
  WeightUpdate,
} from '@/types/models/search-creators/filterUpdate';
import {
  CreatorSearchResult,
  Metadata,
} from '@/types/models/search-creators/searchCreators';
import {
  Paging,
  SearchCreatorsStateDefinition,
  ViewType,
} from '@/types/models/search-creators/searchCreatorsStore';
import {
  DefaultSortDirection,
  DefaultSortField,
} from '@/types/models/search-creators/sortField';
import {SortDirection} from '@/types/models/search-creators/sorting';
import {
  engagementRateColumn,
  followersColumn,
  nameColumn,
} from '@/stores/search-creators/columnInstance';
import initialFiltersGroups from '@/stores/search-creators/filterGroupInstance';
import {
  findExpandedFilter,
  getSortFieldFromString,
  getDirectOrNestedFilter,
  hasSelectedFilters,
  isFiltersGroupChanged,
  resetFilterDrawerState,
  resetSelected,
  resetSessionInformation,
  updateAsyncInitializableFilterOptions,
  updateLda,
  updateWeight,
  setUpdatedFilter,
} from '@/stores/search-creators/utils';

export const SEARCH_CREATORS_ITEMS_PER_PAGE = 30;
export const VIEW_TYPE_TABLE = 'table';
export const VIEW_TYPE_GRID = 'grid';
const STORAGE_SORT_KEY = 'searchCreatorsSort';

export const initialPaging: Paging = {
  page: 1,
  itemsPerPage: SEARCH_CREATORS_ITEMS_PER_PAGE,
  totalResults: 0,
};

export const initialSearchCreatorsState: SearchCreatorsStateDefinition = {
  viewType: VIEW_TYPE_GRID,
  searchQuery: '',
  isMetadataLoaded: false,
  selectedCampaignId: '',
  selectedBrandId: '',
  content: {
    sessionId: undefined,
    columns: [nameColumn, engagementRateColumn, followersColumn],
    paging: initialPaging,
    sorting: Storage.getItemSync(STORAGE_SORT_KEY) || {
      field: DefaultSortField,
      direction: DefaultSortDirection,
    },
    selectedCreators: new Map(),
    filtersState: {
      filtersGroups: initialFiltersGroups,
      filtersGroupsSnapshot: initialFiltersGroups,
    },
  },
};

const devtoolsOptions: DevtoolsOptions = {
  enabled: getEnv().MODE !== 'production',
  serialize: {options: {map: true}},
};

const searchCreatorsStore = create<SearchCreatorsStateDefinition>()(
  devtools(
    immer(() => initialSearchCreatorsState),
    devtoolsOptions
  )
);

const updateUserLastBrandId = (brandId: string) =>
  searchCreatorsStore.setState((draft) => {
    draft.selectedBrandId = brandId;
  });

const updateUserSelectedCampaignId = (selectedCampaignId: string) =>
  searchCreatorsStore.setState((draft) => {
    draft.selectedCampaignId = selectedCampaignId;
    resetSessionInformation(draft.content);
  });

const updateSearchQuery = (searchQuery: string) => {
  searchCreatorsStore.setState((draft) => {
    draft.searchQuery = searchQuery;
    resetSessionInformation(draft.content);
  });
};

const changePage = (page: number) =>
  searchCreatorsStore.setState((draft) => {
    draft.content.paging.page = page;
  });

const setSessionInformation = (sessionId: string) =>
  searchCreatorsStore.setState((draft) => {
    draft.content.sessionId = sessionId;
  });

const changeViewType = (viewType: ViewType) =>
  searchCreatorsStore.setState(() => ({viewType}));

const selectCreator = (creator: CreatorSearchResult, isSelected: boolean) =>
  searchCreatorsStore.setState((draft) => {
    if (isSelected) {
      draft.content.selectedCreators.set(creator.id, creator);
    } else {
      draft.content.selectedCreators.delete(creator.id);
    }
  });

const toggleSelectAllCreatorsOnPage = (
  creators: CreatorSearchResult[],
  isSelected: boolean
) =>
  searchCreatorsStore.setState((draft) => {
    if (isSelected) {
      creators.forEach((creator) => {
        draft.content.selectedCreators.set(creator.id, creator);
      });
    } else {
      creators.forEach((creator) => {
        draft.content.selectedCreators.delete(creator.id);
      });
    }
  });

const clearSelectedCreators = () =>
  searchCreatorsStore.setState((draft) => {
    draft.content.selectedCreators = new Map();
  });

const changeSorting = (
  sortField: string,
  direction: SortDirection = DefaultSortDirection
) =>
  searchCreatorsStore.setState((draft) => {
    draft.content.sessionId = undefined;
    draft.content.paging.page = 1;
    draft.content.paging.totalResults = 0;
    const sorting = {
      field: getSortFieldFromString(sortField) ?? DefaultSortField,
      direction,
    };
    draft.content.sorting = sorting;
    draft.content.selectedCreators = new Map();
    Storage.setItem(STORAGE_SORT_KEY, sorting);
  });

const openFiltersDrawer = (filtersGroupId: FiltersGroupId) =>
  searchCreatorsStore.setState((draft) => {
    draft.content.filtersState.openedFiltersGroup = filtersGroupId;
    draft.content.filtersState.filtersGroupsSnapshot =
      draft.content.filtersState.filtersGroups;
  });

const closeFiltersDrawer = () =>
  searchCreatorsStore.setState((draft) => {
    draft.content.filtersState.openedFiltersGroup = undefined;
    draft.content.filtersState.filtersGroups =
      draft.content.filtersState.filtersGroupsSnapshot;
  });

const resetFiltersGroup = () => {
  const {openedFiltersGroup} =
    searchCreatorsStore.getState().content.filtersState;
  if (!openedFiltersGroup) {
    return;
  }
  const initialFiltersGroup = initialFiltersGroups[openedFiltersGroup];
  searchCreatorsStore.setState((draft) => {
    const {filters} =
      draft.content.filtersState.filtersGroups[openedFiltersGroup];
    resetFilterDrawerState(draft.content.filtersState);
    if (hasSelectedFilters(filters)) {
      resetSessionInformation(draft.content);
      resetPlatformMetrics(openedFiltersGroup, filters);
      Object.keys(filters).forEach((filterId) => {
        const id = filterId as FilterIds;
        const filter = filters[id];
        const initialFilter = initialFiltersGroup.filters[id];
        if (filter && initialFilter) {
          resetSelected(filter, initialFilter);
          draft.content.filtersState.filtersGroupsSnapshot[openedFiltersGroup] =
            draft.content.filtersState.filtersGroups[openedFiltersGroup];
        }
      });
    }
  });
};

const resetAllFilters = (forceShouldResetSession = false) => {
  searchCreatorsStore.setState((draft) => {
    draft.content.filtersState.openedFiltersGroup = undefined;
    let shouldResetSession = false;
    const {filtersGroups} = draft.content.filtersState;
    Object.keys(filtersGroups).forEach((_filtersGroupId) => {
      const filtersGroupId = _filtersGroupId as FiltersGroupId;
      const initialFiltersGroup = initialFiltersGroups[filtersGroupId];
      const {filters} = filtersGroups[filtersGroupId];
      filtersGroups[filtersGroupId].expandedFilter = undefined;
      resetPlatformMetrics(filtersGroupId, filters);
      if (hasSelectedFilters(filters)) {
        shouldResetSession = true;
        Object.keys(filters).forEach((_filterId) => {
          const filterId = _filterId as FilterIds;
          const filter = filters[filterId];
          const initialFilter = initialFiltersGroup.filters[filterId];
          if (filter && initialFilter) {
            resetSelected(filter, initialFilter);
          }
        });
      }
    });
    if (shouldResetSession || forceShouldResetSession) {
      resetSessionInformation(draft.content);

      draft.content.filtersState.filtersGroupsSnapshot =
        draft.content.filtersState.filtersGroups;
    }
  });
};

function resetPlatformMetrics(
  filterGroupId: FiltersGroupId,
  filters: WritableDraft<Partial<Record<FilterIds, Filter>>>
) {
  if (filterGroupId === 'platforms') {
    const platformMetrics =
      filters.platformMetrics as WritableDraft<DynamicPlatformFilters>;
    platformMetrics.platformId = (
      initialFiltersGroups[filterGroupId].filters
        .platformMetrics as DynamicPlatformFilters
    ).platformId;
  }
}

const applyFiltersGroup = () => {
  const {filtersGroups, filtersGroupsSnapshot, openedFiltersGroup} =
    searchCreatorsStore.getState().content.filtersState;
  if (!openedFiltersGroup) {
    return;
  }

  const applyFilters = isFiltersGroupChanged(
    filtersGroups[openedFiltersGroup].filters,
    filtersGroupsSnapshot[openedFiltersGroup].filters
  );

  searchCreatorsStore.setState((draft) => {
    resetFilterDrawerState(draft.content.filtersState);
    if (applyFilters) {
      resetSessionInformation(draft.content);
      draft.content.filtersState.filtersGroupsSnapshot[openedFiltersGroup] =
        draft.content.filtersState.filtersGroups[openedFiltersGroup];
    }
  });
};

const applyFiltersFromFilterGroups = () => {
  const {filtersGroups} = searchCreatorsStore.getState().content.filtersState;
  searchCreatorsStore.setState((draft) => {
    draft.content.filtersState.filtersGroupsSnapshot = filtersGroups;
  });
};

const toggleExpandFilter = (
  filtersGroupId: FiltersGroupId,
  filterId: FilterIds
) => {
  const {filtersState} = searchCreatorsStore.getState().content;
  if (filtersState.openedFiltersGroup !== filtersGroupId) {
    return;
  }
  const filtersGroup = filtersState.filtersGroups[filtersGroupId];
  if (filtersGroup) {
    const isExpanded = filtersGroup.expandedFilter === filterId;
    searchCreatorsStore.setState((draft) => {
      draft.content.filtersState.filtersGroups[filtersGroupId].expandedFilter =
        isExpanded ? undefined : filterId;
    });
  }
};

const changeUserInputFilter = (
  filtersGroupId: FiltersGroupId,
  filterId: FilterIds,
  input: string,
  platformFilterId?: PlatformFilterIds
) => {
  const {filtersState} = searchCreatorsStore.getState().content;
  const filter = findExpandedFilter(
    filtersState,
    'multiUserInput',
    filtersGroupId,
    filterId,
    platformFilterId
  );
  if (typeof filter === 'undefined') {
    console.log('Invalid input - operation is not possible');
    return;
  }
  const inputFilter = filter as MultiUserInput;
  const updatedInputs = inputFilter.inputs.includes(input)
    ? inputFilter.inputs.filter((_input) => _input !== input)
    : [...inputFilter.inputs, input];
  const updatedFilter = {...inputFilter, inputs: updatedInputs};
  setFilter(filtersGroupId, filterId, updatedFilter, platformFilterId);
};

const changeSingleUserInputFilter = (
  filtersGroupId: FiltersGroupId,
  filterId: FilterIds,
  input: string,
  platformFilterId?: PlatformFilterIds
) => {
  const {filtersState} = searchCreatorsStore.getState().content;
  const filter = findExpandedFilter(
    filtersState,
    'singleUserInput',
    filtersGroupId,
    filterId,
    platformFilterId
  );
  if (typeof filter === 'undefined') {
    console.log('Invalid input - operation is not possible');
    return;
  }
  const inputFilter = filter as SingleUserInput;
  const updatedFilter = {...inputFilter, input} as SingleUserInput;
  setFilter(filtersGroupId, filterId, updatedFilter, platformFilterId);
};

const changeFilterRange = (
  filtersGroupId: FiltersGroupId,
  filterId: FilterIds,
  range?: Range,
  platformFilterId?: PlatformFilterIds
) => {
  const {filtersState} = searchCreatorsStore.getState().content;
  const filter = findExpandedFilter(
    filtersState,
    'rangeSelection',
    filtersGroupId,
    filterId,
    platformFilterId
  );

  if (typeof filter === 'undefined') {
    console.log('Invalid input - operation is not possible');
    return;
  }
  const updatedFilter = {...(filter as RangeSelection), selected: range};
  setFilter(filtersGroupId, filterId, updatedFilter, platformFilterId);
};

const changeFilterOptionSelection = (
  filterSelectionType: FilterOptionSelection,
  filtersGroupId: FiltersGroupId,
  filterId: FilterIds,
  option: BaseFilterOption,
  platformFilterId?: PlatformFilterIds
) => {
  const {filtersState} = searchCreatorsStore.getState().content;
  const filter = findExpandedFilter(
    filtersState,
    filterSelectionType,
    filtersGroupId,
    filterId,
    platformFilterId
  );

  const updatedFilter = setUpdatedFilter(filter, filterSelectionType, option);
  if (updatedFilter) {
    setFilter(filtersGroupId, filterId, updatedFilter, platformFilterId);
  }
  setPlatformMetricsPlatformId(option, filtersState, filtersGroupId, filterId);
  handleAudienceFiltersRelevance(option, filtersGroupId, filterId);
};

const forceChangeFilterRange = (
  filtersGroupId: FiltersGroupId,
  filterId: FilterIds,
  range?: Range,
  platformFilterId?: PlatformFilterIds
) => {
  const {filtersState} = searchCreatorsStore.getState().content;
  const filterGroup = filtersState.filtersGroups[filtersGroupId];
  const filter = getDirectOrNestedFilter(
    filterGroup.filters,
    filterId,
    platformFilterId
  );

  if (typeof filter === 'undefined') {
    console.log('Invalid input - operation is not possible');
    return;
  }
  const updatedFilter = {...(filter as RangeSelection), selected: range};
  setFilter(filtersGroupId, filterId, updatedFilter, platformFilterId);
};

const forceChangeFilterOptionSelection = (
  filterSelectionType: FilterOptionSelection,
  filtersGroupId: FiltersGroupId,
  filterId: FilterIds,
  option: BaseFilterOption,
  platformFilterId?: PlatformFilterIds
) => {
  const {filtersState} = searchCreatorsStore.getState().content;
  const filterGroup = filtersState.filtersGroups[filtersGroupId];
  const filter = getDirectOrNestedFilter(
    filterGroup.filters,
    filterId,
    platformFilterId
  );

  const updatedFilter = setUpdatedFilter(filter, filterSelectionType, option);
  if (updatedFilter) {
    setFilter(filtersGroupId, filterId, updatedFilter, platformFilterId);
  }
  setPlatformMetricsPlatformId(option, filtersState, filtersGroupId, filterId);
  handleAudienceFiltersRelevance(option, filtersGroupId, filterId);
};

function setPlatformMetricsPlatformId(
  option: BaseFilterOption,
  filtersState: FiltersState,
  filtersGroupId: FiltersGroupId,
  filterId: FilterIds
) {
  if (filtersGroupId !== 'platforms' || filterId !== 'platform') return;
  const platformMetrics = filtersState.filtersGroups.platforms.filters
    .platformMetrics as DynamicPlatformFilters;

  const updatedPlatformMetrics = produce(platformMetrics, (draft) => {
    const oldPlatformFilters =
      platformMetrics.platformsFilters[draft.platformId];
    const newPlatformFilters = draft.platformsFilters[option.id as PlatformId];

    /* TODO: check we have newPlatformFilters */
    if (!newPlatformFilters) return;
    Object.keys(oldPlatformFilters).forEach((id) => {
      const platformFilterId = id as PlatformFilterIds;
      const oldFilter = oldPlatformFilters[platformFilterId];
      const newFilter = newPlatformFilters[platformFilterId];
      if (!oldFilter || !newFilter) return;
      switch (oldFilter.type) {
        case 'singleSelection':
          (newFilter as SingleSelection<BaseFilterOption>).selected = (
            oldFilter as SingleSelection<BaseFilterOption>
          ).selected;
          break;
        case 'rangeSelection':
          (newFilter as RangeSelection).selected = (
            oldFilter as RangeSelection
          ).selected;
          break;
        default: // There are no other types in dynamic platform filters currently
      }
    });

    draft.platformId = option.id as PlatformId;
  });
  setFilter(filtersGroupId, 'platformMetrics', updatedPlatformMetrics);
}

function handleAudienceFiltersRelevance(
  option: BaseFilterOption,
  filtersGroupId: FiltersGroupId,
  filterId: FilterIds
) {
  if (filtersGroupId !== 'platforms' || filterId !== 'platform') return;
  searchCreatorsStore.setState((draft) => {
    const filters = draft.content.filtersState.filtersGroups.platforms
      .filters as WritableDraft<Record<PlatformFilterId, Filter>>;
    const isIrrelevant = option.id !== 'instagram';
    filters.age.isIrrelevant = isIrrelevant;
    filters.gender.isIrrelevant = isIrrelevant;
    filters.location.isIrrelevant = isIrrelevant;
  });
}

const updateFilterOption = (
  filterSelectionType: UpdatableFilterOptionSelection,
  filtersGroupId: FiltersGroupId,
  filterId: FilterIds,
  update: FilterUpdate
) => {
  const {filtersState} = searchCreatorsStore.getState().content;
  const filter = findExpandedFilter(
    filtersState,
    filterSelectionType,
    filtersGroupId,
    filterId
  );
  if (typeof filter === 'undefined') {
    console.log('Invalid input - operation is not possible');
    return;
  }
  let updatedFilter;
  switch (update.updateType) {
    case 'lda':
      updatedFilter = updateLda(
        filterSelectionType,
        filter,
        update as LdaUpdate
      );
      break;
    case 'weight':
      updatedFilter = updateWeight(
        filterSelectionType,
        filter,
        update as WeightUpdate
      );
      break;
    default:
  }
  if (updatedFilter) {
    setFilter(filtersGroupId, filterId, updatedFilter);
  }
};

const setFiltersOptionsFromMetadata = (metadata: Metadata) => {
  searchCreatorsStore.setState((draft) => {
    draft.isMetadataLoaded = true;
    const filterGroups = Object.values(
      draft.content.filtersState.filtersGroups
    );
    filterGroups.forEach((group) => {
      const filters = Object.values(group.filters);
      filters.forEach((filter) => {
        updateAsyncInitializableFilterOptions(filter, metadata);
      });
    });
  });
};

function setFilter(
  filtersGroupId: FiltersGroupId,
  filterId: FilterIds,
  filter: Filter,
  platformFilterId?: PlatformFilterIds
) {
  searchCreatorsStore.setState((draft) => {
    const filtersGroup =
      draft.content.filtersState.filtersGroups[filtersGroupId];

    if (platformFilterId) {
      const dynamicPlatformFilters = filtersGroup.filters[
        filterId
      ] as WritableDraft<DynamicPlatformFilters>;

      const platformFilters =
        dynamicPlatformFilters.platformsFilters[
          dynamicPlatformFilters.platformId
        ];
      platformFilters[platformFilterId] = filter;
    } else {
      filtersGroup.filters[filterId] = filter;
    }
  });
}

const initFromUrl = (newState: SearchCreatorsStateDefinition) =>
  searchCreatorsStore.setState(newState, true);

const setTotalResults = (totalResults: number) => {
  searchCreatorsStore.setState((draft) => {
    draft.content.paging.totalResults = totalResults;
  });
};

export const searchCreatorsActions = {
  initFromUrl,
  updateSearchQuery,
  changePage,
  setSessionInformation,
  changeViewType,
  selectCreator,
  toggleSelectAllCreatorsOnPage,
  clearSelectedCreators,
  changeSorting,
  // filters
  openFiltersDrawer,
  closeFiltersDrawer,
  resetFiltersGroup,
  resetAllFilters,
  applyFiltersGroup,
  applyFiltersFromFilterGroups,
  toggleExpandFilter,
  changeFilterOptionSelection,
  forceChangeFilterOptionSelection,
  forceChangeFilterRange,
  changeFilterRange,
  updateFilterOption,
  changeUserInputFilter,
  changeSingleUserInputFilter,
  setFiltersOptionsFromMetadata,
  updateUserSelectedCampaignId,
  updateUserLastBrandId,
  setTotalResults,
};

export default searchCreatorsStore;
