import CREATORS_MOCK from '@/mocks/brand-safety/creators_v2.json';
import mapKeysToCase from '@/utils/mapKeysToCase';
import {CreatorSafetyReport} from '@/types/creatorSafetyReport';
import QueryContext from '../QueryContext';
import api from '../index';

const defaultCreatorId = '1';

const vettingFetchers = {
  report: (context: QueryContext) => {
    let creatorData = CREATORS_MOCK[defaultCreatorId];
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    creatorData = CREATORS_MOCK[context.meta?.creatorId] || creatorData;
    return mapKeysToCase(creatorData) as unknown as CreatorSafetyReport;
  },
  // report: (context: QueryContext) =>
  //   api.get(`/creators/${context.meta?.creatorId}/brands_safety_report`, {
  //     params: {
  //       brand_id: context.meta?.brandId,
  //       report_id: context.meta?.reportId,
  //     },
  //   }),
};

export default vettingFetchers;
