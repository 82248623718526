import React, {useEffect, useState} from 'react';

const CARD_WIDTH_MIN = 255; // Adding the inline margin that the shadow adds
const DEFAULT_CARD_HEIGHT = 532;
const VERTICAL_GAP = 30;
const HORIZONTAL_GAP = 17;

interface VirtualizedGridContainerProps {
  gap: {x: number; y: number};
  columns: number;
  itemSize: {width: number; height: number};
}

function useContentLibraryVirtualizedGridProps(
  virtualizedGridContainerRef: React.RefObject<HTMLDivElement>,
  dependencies: unknown[] = []
): VirtualizedGridContainerProps {
  const [virtualizedGridProps, setVirtualizedGridProps] = useState({
    gap: {x: HORIZONTAL_GAP, y: VERTICAL_GAP},
    columns: 1,
    itemSize: {
      width: CARD_WIDTH_MIN,
      height: DEFAULT_CARD_HEIGHT,
    },
  });

  useEffect(() => {
    function onResize() {
      if (!virtualizedGridContainerRef?.current) {
        return;
      }
      const containerWidth = virtualizedGridContainerRef.current.clientWidth;
      if (containerWidth) {
        const columns = Math.max(
          Math.floor(containerWidth / (CARD_WIDTH_MIN + HORIZONTAL_GAP)),
          1
        );
        const itemWidth =
          (containerWidth - HORIZONTAL_GAP * (columns - 1)) / columns;

        setVirtualizedGridProps({
          gap: {x: HORIZONTAL_GAP, y: VERTICAL_GAP},
          columns,
          itemSize: {
            width: itemWidth,
            height: DEFAULT_CARD_HEIGHT,
          },
        });
      }
    }
    onResize(); // Call onResize for initial setup
    window.addEventListener('resize', onResize);
    return () => window.removeEventListener('resize', onResize);
  }, [virtualizedGridContainerRef?.current?.clientWidth, ...dependencies]);

  return virtualizedGridProps;
}

export default useContentLibraryVirtualizedGridProps;
