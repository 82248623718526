import React from 'react';
import {designSystemToken, Icon, Label} from '@lightricks/react-design-system';
import translate from '@/utils/translate';
import {ROUTES} from '@/config/routesConstants';
import Link from '@/components/link';
import styles from './ProfileLink.module.scss';

const TRANSLATION_PREFIX = 'components.profile-link';

interface ProfileLinkProps {
  profileId: string;
}

function ProfileLink({profileId}: ProfileLinkProps) {
  return (
    <Link
      className={styles.profileLink}
      to={`${ROUTES.CREATOR_PROFILE}/${profileId}`}
      target="_blank"
    >
      <div className={styles.label}>
        <Icon size="medium" appearance="neutral" name="Actions-Send" />
        <Label size="md" color={designSystemToken('semantic.fg.primary')}>
          {translate(`${TRANSLATION_PREFIX}.go-to-full-profile`)}
        </Label>
      </div>
    </Link>
  );
}

export default ProfileLink;
